// libraries
import React from 'react';

import Head from 'components/Head';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';

import DocsLayout from './Layout';

interface Props {
  pageContext: { value: Define };
}

export default function DefineDocs(props: Props) {
  const { value } = props.pageContext;

  return (
    <MainLayout>
      <Head title={value.id} />
      <DocsLayout title={value.id}>
        <Markdown forceBlock>{value.brief || ''}</Markdown>
        <Markdown forceBlock>{value.details || ''}</Markdown>
      </DocsLayout>
    </MainLayout>
  );
}
